@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type="text"]:focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="datetime-local"]:focus,
  [type="month"]:focus,
  [type="search"]:focus,
  [type="tel"]:focus,
  [type="time"]:focus,
  [type="week"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    --tw-ring-color: #27823d;
    border-color: var(--tw-ring-color);
  }

  [type="checkbox"],
  [type="radio"] {
    color: #27823d;
  }

  [type="checkbox"]:focus,
  [type="radio"]:focus {
    --tw-ring-color: #27823d;
  }

  button:focus,
  a:focus {
    box-shadow: 0 0 0 3px rgba(39, 130, 61, 0.5);
    border-color: #27823d;
    outline: none;
  }
}

@layer components {
  .heading {
    @apply text-3xl font-bold text-gray-900;
  }
  .subheading {
    @apply text-2xl font-bold text-gray-800;
  }
}
